import { ApiService } from "@app/services/api.service";
import { Injectable } from "@angular/core";
import { PARAMS } from "@app/config/params";
import { environment } from "../../environments/environment";
import { Observable } from "rxjs";


@Injectable({
  providedIn: "root",
})
export class CommonService {

  constructor(private apiService: ApiService) { }

  getAllCountries(data) {
    const url = `${environment.accountService.baseUrl}${PARAMS.ACCOUNT_SERVICE.MODULES.COUNTRY.SEARCH.URL}?q=${data}`;
    return this.apiService.get(url);
  }

  getCountries() {
    const url = `${environment.accountService.baseUrl}${PARAMS.ACCOUNT_SERVICE.MODULES.COUNTRY.GET_COUNTRIES.URL}`;
    return this.apiService.get(url);
  }

  updateStatus(id, status, module,auditRemarks = "") {

    let url = `${environment.accountService.baseUrl}${PARAMS.ACCOUNT_SERVICE.MODULES.DASHBOARD.ENABLE.URL}${id}?isEnable=${status}&entityType=${module}&auditRemarks=${auditRemarks}`;

    return this.apiService.put(url, {});
  }

  getCompanyById(companyId) {
    const url = `${environment.accountService.baseUrl}${PARAMS.ACCOUNT_SERVICE.MODULES.COMPANY.GET_COMPANY_BY_ID.URL}${companyId}`;
    return this.apiService.get(url);
  }

  public getRegion() {
    const url = `${environment.accountService.baseUrl}${PARAMS.ACCOUNT_SERVICE.MODULES.REGION.GET_REGIONS.URL}`;
    return this.apiService.get(url);
  }

  getRegionById(regionId) {
    const url = `${environment.accountService.baseUrl}${PARAMS.ACCOUNT_SERVICE.MODULES.REGION.GET_REGION_BY_ID.URL}${regionId}`;
    return this.apiService.get(url);
  }

  getCountryById(countryId) {
    const url = `${environment.accountService.baseUrl}${PARAMS.ACCOUNT_SERVICE.MODULES.COUNTRY.GET_COUNTRY_BY_ID.URL}${countryId}`;
    return this.apiService.get(url);
  }

  getTeamById(teamId) {
    const url = `${environment.accountService.baseUrl}${PARAMS.ACCOUNT_SERVICE.MODULES.TEAMS.GET_TEAM_BY_ID.URL}${teamId}`;
    return this.apiService.get(url);
  }

  getKamEmailById(data) {
    const url = `${environment.accountService.baseUrl}${PARAMS.ACCOUNT_SERVICE.MODULES.USER.GET_USER_BY_EMAIL.URL}`;
    return this.apiService.post(url, data);
  }

  getCurrencies() {
    const url = `${environment.accountService.baseUrl}${PARAMS.ACCOUNT_SERVICE.MODULES.CURRENCY.GET_CURRENCIES.URL}`;
    return this.apiService.get(url);
  }

  getCitiesByState(stateId) {
    const url = `${environment.accountService.baseUrl}${PARAMS.ACCOUNT_SERVICE.MODULES.CITY.GET_CITIES_BY_STATE.URL}${stateId}`;
    return this.apiService.get(url);
  }
  getStatesByCountry(countryId) {
    const url = `${environment.accountService.baseUrl}${PARAMS.ACCOUNT_SERVICE.MODULES.STATE.GET_STATES_BY_COUNTRY.URL}${countryId}`;
    return this.apiService.get(url);
  }
  getVerticalById(verticalId) {
    const url = `${environment.accountService.baseUrl}${PARAMS.ACCOUNT_SERVICE.MODULES.VERTICAL.GET_VERTICAL_BY_ID.URL}${verticalId}`;
    return this.apiService.get(url);
  }

  getPlantDetails(data): Observable<any> {
    let url = `${environment.accountService.baseUrl}${PARAMS.USER_MANAGEMENT.MODULES.PLANTS.GET_PLANT.URL}/${data.idBranch}`;
    return this.apiService.get(url);
  }

  fileUpload(body?: any) {
    let url = `${environment.accountService.baseUrl}${PARAMS.USER_MANAGEMENT.MODULES.FILE_UPLOAD.URL}`;
    return this.apiService.post(url, body);
  }

  // getAllSourcingEmails():Observable<any> {
  //   const url = `${environment.accountService.baseUrl}${PARAMS.USER_MANAGEMENT.MODULES.GET_SOURCER_LIST.URL}`;
  //   return this.apiService.get(url);
  // }

  getAllSourcingEmails(activeSheet):Observable<any> {
    const url = `${environment.buyerInternal.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.PO.SOURCERS_LIST.URL}/${activeSheet}`;
    return this.apiService.get(url);
  }

  getPaymentTerms(){
    const url = `${environment.accountService.baseUrl}${PARAMS.ACCOUNT_SERVICE.MODULES.PAYMENT_TERMS.GET_PAYMENT_TERMS.URL}`;
    return this.apiService.get(url);
  }

  getTSFSPlantCreateDetails(data):Observable<any>{
    const url = `${environment.freshService.baseUrl}${PARAMS.ACCOUNT_SERVICE.MODULES.FRESH_SERVICE.GET_PLANT_CREATE_DETAILS.URL}${data.ticketId}/requested_items`;
    return this.apiService.get(url);
  }

  getGSTNDetails(data):Observable<any>{
    const url = `${environment.finance.baseUrl}${PARAMS.ACCOUNT_SERVICE.MODULES.FINANCE.GET_GSTN_DETAILS.URL}?gstin=${data}`;
    return this.apiService.get(url);
  }

}
