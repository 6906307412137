import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { LayoutComponent } from "./components/layout/layout.component";
import { HeaderComponent } from "./components/header/header.component";
import { AccessDeniedComponent } from "./components/access-denied/access-denied.component";
import { NotFoundComponent } from "./components/not-found/not-found.component";
import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { GuestLayoutComponent } from "./components/guest-layout/guest-layout.component";
import { MainLayoutComponent } from "./components/main-layout/main-layout.component";
import { ConfirmDeleteDialogComponent } from "./components/confirm-delete-dialog/confirm-delete-dialog.component";
import { AppMaterialModule } from "@app/material-module";
import { DownloadBatchDialogComponent } from "./components/download-batch-dialog/download-batch-dialog.component";
import { ErrorMsgComponent } from "./components/error-msg/error-msg.component";
import { TpSupplierDetailsDialogComponent } from "./components/tp-supplier-details-dialog/tp-supplier-details-dialog.component";
import { AuditLogItemsDialogComponent } from "@app/modules/shared/components/audit-log-dialog/audit-log-dialog.component";
import { ConfirmationComponent } from "./components/confirmation/confirmation.component";
import { CustomPaginatorComponent } from "./custom-paginator/custom-paginator.component";
import { RoutingConfigurationDialogComponent } from "./components/routing-configuration-dialog/routing-configuration-dialog.component";
import { MatTooltipModule } from "@angular/material/tooltip";
import { AvailableInventoryVisibilityDialogComponent } from './components/available-inventory-visibility-dialog/available-inventory-visibility-dialog.component';
import { ErrorValidationsDialogComponent } from './components/error-validations-dialog/error-validations-dialog.component';
import { MsnwiseSpDetailsDialogComponent } from './components/msnwise-sp-details-dialog/msnwise-sp-details-dialog.component';
import { SupplierSuggestListDialogComponent } from './components/supplier-suggest-list-dialog/supplier-suggest-list-dialog.component';
import { PendencyPopupComponent } from './components/header/pendency-popup/pendency-popup.component';
import { MenuBarComponent } from './components/menu-bar/menu-bar.component';
import { GlobalSearchComponent } from "../po/components/global-search/global-search.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { AskExpertComponent } from './components/ask-expert/ask-expert.component';

@NgModule({
  declarations: [
    LayoutComponent,
    HeaderComponent,
    AccessDeniedComponent,
    NotFoundComponent,
    SidebarComponent,
    GuestLayoutComponent,
    MainLayoutComponent,
    ConfirmDeleteDialogComponent,
    DownloadBatchDialogComponent,
    ErrorMsgComponent,
    TpSupplierDetailsDialogComponent,
    AuditLogItemsDialogComponent,
    RoutingConfigurationDialogComponent,
    ConfirmationComponent,
    CustomPaginatorComponent,
    AvailableInventoryVisibilityDialogComponent,
    ErrorValidationsDialogComponent,
    MsnwiseSpDetailsDialogComponent,
    SupplierSuggestListDialogComponent,
    PendencyPopupComponent,
    MenuBarComponent,
    GlobalSearchComponent,
    AskExpertComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    AppMaterialModule,
    MatTooltipModule,
    ReactiveFormsModule,
    FormsModule,
    NgxMatSelectSearchModule
  ],
  exports: [
    LayoutComponent,
    ConfirmDeleteDialogComponent,
    DownloadBatchDialogComponent,
    ErrorMsgComponent,
    TpSupplierDetailsDialogComponent,
    AuditLogItemsDialogComponent,
    CustomPaginatorComponent,
    RoutingConfigurationDialogComponent,
    AvailableInventoryVisibilityDialogComponent,
    ErrorValidationsDialogComponent,
    MsnwiseSpDetailsDialogComponent,
    SupplierSuggestListDialogComponent,
    GlobalSearchComponent,
    AskExpertComponent,
  ],
  entryComponents: [
    ConfirmDeleteDialogComponent,
    DownloadBatchDialogComponent,
    TpSupplierDetailsDialogComponent,
    AuditLogItemsDialogComponent,
    ConfirmationComponent,
    RoutingConfigurationDialogComponent,
    AvailableInventoryVisibilityDialogComponent,
    ErrorValidationsDialogComponent,
    MsnwiseSpDetailsDialogComponent,
    SupplierSuggestListDialogComponent,
    GlobalSearchComponent
  ],
})
export class SharedModule { }
