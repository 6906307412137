<div class="menu-bar active">
  <div class="menubarinner">
    <ul>
      <li [routerLink]="['/report/po-at-hand']" routerLinkActive="active" (click)="googleTracking('Dashboard')">
        <div class="link">
          <span>Dashboard</span>
        </div>
      </li>
      <li routerLinkActive="active">
        <div class="link" data-toggle="collapse">
          <span>CPO</span>
          <i class="material-icons secondicon">expand_more </i>
        </div>
        <ul class="submenu">
          <ng-container *ngFor="let po of APP_ROUTES.PO">
            <ng-container *ngIf="!po?.hasSubMenu; else SubMenu">
              <li>
                <div class="link" [routerLink]="po.link" [queryParams]="po?.queryParams" routerLinkActive="active" (click)="googleTracking(po?.ga)">
                  <span>{{po.title}}</span>
                </div>
              </li>
            </ng-container>
            <ng-template #SubMenu>
              <li>
                <div class="link expeandable ">
                  <span>{{po.title}}</span>
                </div>
                <div class="sub-menus expended">
                  <ng-container *ngFor="let menu of po?.subMenus; let i = index">
                    <ng-container *ngIf="menu?.isAccessBased; else notAccessBased">
                      <ng-container *ngIf="po.title == 'Bulk Punching' && paasCpoSpoPunching">
                        <div class="sub-menu-item" [routerLink]="menu?.link" routerLinkActive="active" (click)="googleTracking(menu?.ga)">
                          {{menu?.title}}
                        </div>
                      </ng-container>
                    </ng-container>
                    <ng-template #notAccessBased>
                      <div class="sub-menu-item" [routerLink]="menu?.link" routerLinkActive="active" (click)="googleTracking(menu?.ga)">
                        {{menu?.title}}
                      </div>
                    </ng-template>
                  </ng-container>
                </div>
              </li>
            </ng-template>
          </ng-container>
        </ul>
      </li>
      <li routerLinkActive="active">
        <div class="link" data-toggle="collapse">
          <span>Enquiry</span>
          <i class="material-icons secondicon">expand_more </i>
        </div>
        <ul class="submenu">
          <li *ngFor="let rfqSheet of APP_ROUTES.RFQ">
            <div class="link" [routerLink]="rfqSheet.link" [queryParams]="rfqSheet?.queryParams" routerLinkActive="active" (click)="googleTracking(rfqSheet?.ga)">
              <span>{{rfqSheet.title}}</span>
            </div>
          </li>
        </ul>
      </li>
      <li routerLinkActive="active" *ngIf="paasAccess">
        <div class="link" data-toggle="collapse">
          <span>PaaS</span>
          <i class="material-icons secondicon">expand_more </i>
        </div>
        <ul class="submenu">
          <li *ngFor="let paas of APP_ROUTES.PAAS">
            <div class="link" [routerLink]="paas.link" [queryParams]="paas?.queryParams" routerLinkActive="active" (click)="googleTracking(paas?.ga)">
              <span>{{paas.title}}</span>
            </div>
          </li>
        </ul>
      </li>
      <li *ngIf="arcAccess" routerLinkActive="active">
        <div class="link" data-toggle="collapse">
          <span>ARC</span>
          <i class="material-icons secondicon">expand_more </i>
        </div>
        <ul class="submenu">
          <li *ngFor="let arc of APP_ROUTES.ARC">
            <div class="link" [routerLink]="arc.link" [queryParams]="arc?.queryParams" routerLinkActive="active" (click)="googleTracking(arc?.ga)">
              <span>{{arc.title}}</span>
            </div>
          </li>
        </ul>
      </li>
      <li routerLinkActive="active">
        <div class="link" data-toggle="collapse">
          <span>Schedule Agreement</span>
          <i class="material-icons secondicon">expand_more </i>
        </div>
        <ul class="submenu">
          <li *ngFor="let sa of APP_ROUTES.SCHEDULE_AGREEMENT">
            <div class="link" [routerLink]="sa.link" [queryParams]="sa?.queryParams" routerLinkActive="active" (click)="googleTracking(sa?.ga)">
              <span>{{sa.title}}</span>
            </div>
          </li>
        </ul>
      </li>
      <li routerLinkActive="active">
        <div class="link" data-toggle="collapse">
          <span>Catalog</span>
          <i class="material-icons secondicon">expand_more</i>
        </div>
        <ul class="submenu">
          <li *ngFor="let rfqSheet of APP_ROUTES.CATALOG">
            <div class="link" [routerLink]="rfqSheet.link" [queryParams]="rfqSheet?.queryParams" routerLinkActive="active" (click)="googleTracking(rfqSheet?.ga)">
              <span>{{rfqSheet.title}}</span>
            </div>
          </li>
        </ul>
      </li>
      <li [routerLink]="['/vendor/details']" routerLinkActive="active" style="background: #F0E68C;">
        <div class="link">
          <span>Vendor Discovery</span>
        </div>
      </li>
      <li (click)="redirectToUrl()" target="_blank">
        <div class="link">
          <span>Product Discovery</span>
        </div>
      </li>
      <ng-container *ngIf="finalList.length > 0">
        <li routerLinkActive="active">
          <div class="link" data-toggle="collapse">
            <span>Account Service</span>
            <i class="material-icons secondicon">expand_more </i>
          </div>
          <ul class="submenu">
            <li *ngFor="let aRoute of finalList">
              <div class="link" [routerLink]="aRoute.link" routerLinkActive="active" (click)="googleTracking(aRoute?.ga)">
                <span>{{aRoute.title}}</span>
              </div>
            </li>
          </ul>
        </li>
      </ng-container>
      <ng-container *ngIf="reportsSubMenu?.length">
        <li routerLinkActive="active">
          <div class="link" data-toggle="collapse">
            <span>Reports</span>
            <i class="material-icons secondicon">expand_more </i>
          </div>
          <ul class="submenu">
            <li *ngFor="let rfqSheet of reportsSubMenu">
              <div class="link" [routerLink]="rfqSheet.link" [queryParams]="rfqSheet?.queryParams" routerLinkActive="active" (click)="googleTracking(rfqSheet?.ga)">
                <span>{{rfqSheet.title}}</span>
              </div>
            </li>
          </ul>
        </li>
      </ng-container>
      <li routerLinkActive="active" class="has-submenu">
        <div class="link">
          <span>Others</span>
          <i class="material-icons secondicon">expand_more </i>
        </div>
        <ul class="submenu">
          <ng-container *ngFor="let menu of otherMenus">
            <ng-container *ngIf="!menu?.hasSubMenu; else subMenu">
              <li>
                <div class="link" [routerLink]="menu.link" [queryParams]="menu?.queryParams" routerLinkActive="active" (click)="googleTracking(menu?.ga)">
                  <span>{{menu.title}}</span>
                </div>
              </li>
            </ng-container>
            <ng-template #subMenu>
              <li>
                <div class="link expeandable">
                  <span>{{menu.title}}</span>
                </div>
                <div class="sub-menus expended">
                  <ng-container *ngFor="let subMenu of menu?.subMenus">
                    <div class="sub-menu-item" [routerLink]="subMenu?.link" routerLinkActive="active" (click)="googleTracking(subMenu?.ga)">
                      {{subMenu?.title}}
                    </div>
                  </ng-container>
                </div>
              </li>
            </ng-template>
          </ng-container>
        </ul>
      </li>
    </ul>
  </div>
</div>