import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GuestLayoutComponent } from '@app/modules/shared/components/guest-layout/guest-layout.component';
import { AuthGuard } from '@app/services/auth.guard';
import { PARAMS } from './config/params';
import { MainLayoutComponent } from './modules/shared/components/main-layout/main-layout.component';

const routes: Routes = [
    {
        component: GuestLayoutComponent,
        path: "account",
        loadChildren: () => import('./modules/account/account.module').then(m => m.AccountModule),
        data: { title: "Account" }
    },
    {
        component: GuestLayoutComponent,
        path: "supplier-items",
        loadChildren: () => import('./modules/supplier-items/supplier-items.module').then(m => m.SupplierItemsModule),
        data: { title: "Supplier Items" }
    },
    {
        component: MainLayoutComponent,
        path: "po",
        loadChildren: () => import("@app/modules/po/po.module").then(m => m.PoModule),
        canActivate: [AuthGuard],
        data: { title: "Po" }
    },
    {
        component: MainLayoutComponent,
        path: "catalog",
        loadChildren: () => import("@app/modules/catalog/catalog.module").then(m => m.CatalogModule),
        canActivate: [AuthGuard],
        data: { title: "Catalog" }
    },
    {
        component: MainLayoutComponent,
        path: "sa",
        loadChildren: () => import("@app/modules/sa/sa.module").then(m => m.ScheduleAgreementModule),
        canActivate: [AuthGuard],
        data: { title: "Schedule Agreement" }
    },
    {
        component: MainLayoutComponent,
        path: "report",
        loadChildren: () => import("@app/modules/report/report.module").then(m => m.ReportModule),
        canActivate: [AuthGuard],
        data: { title: "report" }
    },
    {
        component: MainLayoutComponent,
        path: "pre-po",
        loadChildren: () => import("@app/modules/pre-po/pre-po.module").then(m => m.PrePoModule),
        canActivate: [AuthGuard],
        data: { title: "Pre PO" }
    },
    {
        component: MainLayoutComponent,
        path: "account-services",
        loadChildren: () => import("@app/modules/account-services/account-services.module").then(m => m.AccountServicesModule),
        canActivate: [AuthGuard],
        data: { title: "Account Services" }
    },
    { path: "", redirectTo: PARAMS.BUYER_EXCEL_CLOUD.URLS.RFQ_SHEET, pathMatch: "full" },
    {
        component: MainLayoutComponent,
        path: 'arc', loadChildren: () => import('./modules/arc/arc.module').then(m => m.ArcModule),
        canActivate: [AuthGuard]
    },
    {
        component: MainLayoutComponent,
        path: 'reports', loadChildren: () => import('./modules/reports/reports.module').then(m => m.ReportsModule),
        canActivate: [AuthGuard]
    },
    {
        component: MainLayoutComponent,
        path: "pr",
        loadChildren: () => import("@app/modules/pr/pr.module").then(m => m.PrModule),
        canActivate: [AuthGuard],
        data: { title: "Pr" }
    },
    { path: 'quote-download/:quoteId', loadChildren: () => import('./modules/quote-download/quote-download.module').then(m => m.QuoteDownloadModule) },
    {
        component: MainLayoutComponent,
        path: "vendor",
        loadChildren: () => import("./modules/vendors/vendor-details.module").then(m => m.VendorDetailsModule),
        canActivate: [AuthGuard]
    },
    {
        component: MainLayoutComponent,
        path: "serviceability-analysis",
        loadChildren: () => import("./modules/serviceability-analysis/serviceability-analysis.module").then(m => m.ServiceabilityAnalysisModule),
        canActivate: [AuthGuard]
    },
    {
        component: MainLayoutComponent,
        path: "category-expert",
        loadChildren: () => import("./modules/category-expert/category-expert.module").then(m => m.CategoryExpertModule),
        canActivate: [AuthGuard]
    },
    { path: "**", redirectTo: PARAMS.BUYER_EXCEL_CLOUD.URLS.RFQ_SHEET }

];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
