export const environment = {
  production: false,
  env: "QA1",
  clientId:
    "527261541486-etbg81kjqg406g2j204sp2bd161qvloi.apps.googleusercontent.com",
  microsoft: {
    authorityUrl: 'https://login.microsoftonline.com/421e9584-87ff-424f-95b8-1bf46b70db99/',
    KEY: "7ef76858-5a61-4c38-9937-e94e999bac4a",
    tenantId: "421e9584-87ff-424f-95b8-1bf46b70db99"
  },
  procurement: {
    baseUrl: "https://procurement-qa1.moglilabs.com/",
    // baseUrl: 'https://procurementqa.moglix.com/',
    urls: {
      poAudit: "poaudit/",
    },
  },
  userManagement: {
    baseUrl: "https://buyerauth-qa1.moglilabs.com/",
  },
  buyerInternal: {
    baseUrl: "https://buyerintapi-qa1.moglilabs.com/",
  },
  ems: {
    baseUrl: "https://ems-qa1.moglilabs.com/",
  },
  financeAr: {
    baseUrl: "https://financearqa1.moglilabs.com/",
  },
  salesOps: {
    //baseUrl: 'https://salesopsqa.moglilabs.com/',
    baseUrl: "https://salesopsapi-qa1.moglilabs.com/",
    token: "Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJzYWxlc29wcy1zY21Ab21nbGl4LmNvbSIsImlkUmVmcmVzaFRva2VuIjoxMTYzOTc1MzcsImV4cCI6MTc2MjMwMjYwMCwiaWF0IjoxNzMwODExNDA2LCJlbWFpbCI6InNhbGVzb3BzLXNjbUBvbWdsaXguY29tIn0.lNj0hXG5B-LgE5Hqjsh190AY1QVEpLRKXrv9bu2e7eRRnGqtxwBtX9rgTcpSavQcgyi1n3gg_2fja-1VI00Opw"
  },
  dataSync: {
    baseUrl: "https://datasync-qa1.moglilabs.com/",
  },
  suppliers: {
    baseUrl: "https://supplierapiqa.moglilabs.com/",
  },
  searchSupplier: {
    baseUrl: "https://suppliersearchapiqa.moglilabs.com/",
    token: 'ek792hj901wt80ln827xzgs92',
  },
  online: {
    baseUrl: "https://apiqa.moglilabs.com/",
  },
  online_gt: {
    baseUrl: "https://api-gt.moglilabs.com/api/development/",
  },
  salesOpsSupplier: {
    baseUrl: "https://purchase.moglilabs.com/",
    token: "Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJwdXJjaGFzZS1zY21Ab21nbGl4LmNvbSIsImlkUmVmcmVzaFRva2VuIjo3MzksImV4cCI6MTY3Njk2MzI5NiwiaWF0IjoxNjQ1NDI3Mjk2LCJlbWFpbCI6InB1cmNoYXNlLXNjbUBvbWdsaXguY29tIn0.5S55bBB8BPPsUAdDaYtYGZb1f6kkRKmagykYQEzLf7g2VpmsolIkNzOaZs6V6N0zwUjkEvao_wW3JWvYfz2oKA"
  },
  msnSuggesstion: {
    baseUrl: "https://analysis.moglix.com/",
  },
  centralUserManagment: {
    baseUrl: "https://authqa.ppecovid19.com/",
    token:
      "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJzYWkucHJhZGVlcEBtb2dsaXguY29tIiwiZXhwIjoxNjMxNjQ3ODIxLCJpYXQiOjE2MzE1MjI2NTcsInVzZXJuYW1lIjoic2FpLnByYWRlZXBAbW9nbGl4LmNvbSJ9.QTtWES5Bf9OnSB_8GZgywBI5_Z1mfKSt_C5dKhcxHOg",
  },
  accountService: {
    // baseUrl: "https://dev.account-service.moglilabs.com/",
    baseUrl: "https://mce-account-service-qa1.moglilabs.com/",
  },
  wms: {
    baseUrl: "https://wms-qa1.moglilabs.com/",
    token:
      "Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJwcm9jdXJlbWVudC1idXllcnNAb21nbGl4LmNvbSIsImlkUmVmcmVzaFRva2VuIjoxMTM0NCwiZXhwIjoxNzA2OTM5OTYwLCJpYXQiOjE2NDM4Njc5NjAsImVtYWlsIjoicHJvY3VyZW1lbnQtYnV5ZXJzQG9tZ2xpeC5jb20ifQ.DvRgL8G3_NupPnWeq0g2n6jcbGs7G1RyZyXPCVrfb6lSsBcBAsRFg85-k5NnP4MJD5S6OjC9ohRgOlYbgTeF6w",
  },
  // ARC Module
  meCatalogue: {
    baseUrl: 'https://me-catalogue-service-qa1.moglilabs.com/',
    //baseUrl: 'https://me-catalogue-service.moglix.com/'
    token: "dc1de522-c66c-4280-a388-e9a9c0cb163f"
  },
  analyitcs: {
    baseUrl: 'https://analytics.moglilabs.com/',
  },
  mceNotification: {
    baseUrl: "https://mce-notification-service-qa1.moglilabs.com/"
  },
  mceConfiguration: {
    baseUrl: "https://mce-configuration-service-qa1.moglilabs.com/"
  },
  platform: {
    baseUrl: "https://platformdata-qa1.moglilabs.com/"
  },
  freshService: {
    baseUrl: "https://moglilabs.freshservice.com/",
    token: "Basic VUhvRUlpNlFPNDYyeXpINkZESW86RzkjdFk4bCFtWjNAc0YxcQ=="
  },
  finance: {
    baseUrl: "https://address.moglix.com/",
  },
  cis: {
    baseUrl: "https://cis.moglilabs.com/"
  },
  genAI: {
    baseUrl: "https://genai.moglilabs.com/"
  },
  vendor: {
    baseUrl: "https://serviceability-analysis.moglix.com/"
  },
  analysis: {
    baseUrl: 'https://analytics.moglilabs.com/'
  },
  analyticsWeb: {
    baseUrl: 'https://analytics-web.moglix.com/'
  },
  serviceabilityRoleId: 177,
};