import { Injectable } from '@angular/core';
import { AuthService } from '../auth.service';
import * as lodash from 'lodash';
import { ValidationService } from './validation.service';
import { SHEET_DATA_TYPE } from '@app/config/sheetfields-datatype';
import { PoItemService } from '../po-item.service';
import { lastValueFrom } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class CatalogSheetHelperService {

  validationFieldType = SHEET_DATA_TYPE.CATALOG_SHEET_FIELDS_TYPE;

  constructor(private authService: AuthService,
    private validationService: ValidationService,
    private poItemService: PoItemService) {}

  sheetGroupInfo: Array<any> = [
    { id: 'plantData', class: 'sky-blue', name: 'Plant Data' },
    { id: 'poData', class: 'light-green', name: 'Catalog Data' },
  ]

  defaultSelectedSheetGroup = ['plantData', 'catalogData'];
  coloumsToAudit: Array<string> = ['catalog.remarkFromBusiness'];
  auditItemsMappingKey: any =  {
    "catalog.remarkFromBusiness": "remarkFromBusiness"
  }

  getViewLineItemsObjForCatalog() {
    let lineObject = {
      plantData: {
        title: 'PO Data',
        headingClass: 'basic-info',
        items: {
          status: {
            title: 'Status',
            hide: true,
            value: null,
            disable: true,
          },
          region: {
            title: 'Region',
            hide: false,
            value: null,
            disable: true,
          },
          itemId: {
            title: 'Item Id',
            hide: false,
            value: null,
            disable: true,
          },
          materialDescription: {
            title: 'Material Description',
            hide: false,
            value: null,
            disable: true,
          },
          brand: {
            title: 'Brand',
            hide: false,
            value: null,
            disable: true,
          },
          uom: {
            title: 'Customer UOM',
            hide: false,
            value: null,
            disable: true,
          },
        }
      },
      catalogData: {
        title: 'Catalog Data',
        headingClass: 'sheet-group-podata',
        items: {
          teamName: {
            title: 'Team Name',
            hide: false,
            value: null,
            disable: true,
          },
          isArcRfqType: {
            title: 'CPO Item Type',
            hide: false,
            value: null,
            disable: true,
          },
          buyersPoId: {
            title: 'Buyers PoId',
            hide: false,
            value: null,
            disable: true,
          },
          'product.extraDetails.clientData.annexure': {
            title: 'Additional Description',
            hide: false,
            value: null,
            disable: false,
          },
          'sourcing.suggestedBrand': {
            title: 'Suggested Brand',
            hide: false,
            value: null,
            disable: false,
          },
          plantId: {
            title: 'Plant ID',
            hide: false,
            value: null,
            disable: true,
          },
          plantName: {
            title: 'Plant Name',
            hide: false,
            value: null,
            disable: true,
          },
          cpn: {
            title: 'CPN',
            hide: false,
            value: null,
            disable: true,
          },
          isArc: {
            title: 'Is ARC',
            hide: false,
            value: null,
            disable: true,
          },
          'catalog.urgencyClassification': {
            title: 'Urgency Classification',
            hide: false,
            value: null,
            disable: false,
            inputType: 'dropdown'
          },
          'catalog.priority': {
            title: 'Priority',
            hide: false,
            value: null,
            disable: false,
            inputType: 'dropdown'
          },
          sourcerEmail: {
            title: 'Sourcing POC',
            hide: false,
            value: null,
            disable: false,
          },
          'delivery.opsAllocation': {
            title: 'KAM',
            hide: false,
            value: null,
            disable: false,
          },
          'catalog.assignee': {
            title: 'Assignee',
            hide: false,
            value: null,
            disable: false,
          },
          'catalog.catalogPoc': {
            title: 'Catalog POC',
            hide: false,
            value: null,
            disable: false,
          },
          'catalog.catalogStatus': {
            title: 'Catalog Status',
            hide: false,
            value: null,
            disable: false,
            inputType: 'dropdown'
          },
          msn: {
            title: 'MSN',
            hide: false,
            value: null,
            disable: true,
          },
          msnDescription: {
            title: 'MSN description',
            hide: false,
            value: null,
            disable: true,
          },
          'catalog.clarificationClosed': {
            title: 'Clarification Closed',
            hide: false,
            value: null,
            disable: false,
            inputType: 'dropdown'
          },
          'catalog.remarkFromCatalog': {
            title: 'Remark from Catalog',
            hide: false,
            value: null,
            disable: false,
          },
          'catalog.remarkFromBusiness': {
            title: 'Remark from Business',
            hide: false,
            value: null,
            disable: false,
          },
          rfqMsn: {
            title: 'RFQ MSN',
            hide: false,
            value: null,
            disable: true,
          },
          totalInvoiceValue: {
            title: 'Total Invoice Value',
            hide: false,
            value: null,
            disable: true,
          },
          hsn: {
            title: 'HSN',
            hide: false,
            value: null,
            disable: true,
          },
          tax: {
            title: 'Tax %',
            hide: false,
            value: null,
            disable: true,
          },
          needToWork: {
            title: 'Need to Work ',
            hide: false,
            value: null,
            disable: false,
            inputType: 'dropdown'
          },
          timeOfRequest: {
            title: 'Time of Request',
            hide: false,
            value: null,
            disable: true,
          },
          'catalog.additionalRemark1': {
            title: 'Additional Remark1',
            hide: false,
            value: null,
            disable: false
          },
          'catalog.additionalRemark2': {
            title: 'Additional Remark2',
            hide: false,
            value: null,
            disable: false
          },
          'remarks.remark4': {
            title: 'Additional Input (Algo)',
            hide: false,
            value: null,
            disable: false,
          },
          msnSuggestions: {
            title: 'MSN Suggestions (Algo)',
            hide: false,
            value: null,
            disable: false,
            color: null,
          },
          moglixDescription: {
            title: 'Suggested MSN Description (Algo)',
            hide: false,
            value: null,
            disable: true,
            color: null,
          },
        }
      }
    }
    return lineObject;
  }

  getFieldValue(data: Object, field: String) {
    return lodash.has(data, field) ? data[`${field}`] : ''
  }

  getColoumsToAudit() {
    return this.coloumsToAudit;
  }

  makeItemsData(data) {
    const poDataSet: any[] = [];
    const dataIndex: Object = {};
    let sheetDataIndex = -1;
    for (let i = 0; i < data.length; i++) {
      const lineItemObject = this.getViewLineItemsObjForCatalog();
      const itemData = data[i];
      let plantName = this.getFieldValue(itemData, 'plantName');
      if (lodash.has(itemData, 'plantId') && this.checkEmptyOrNull(plantName)) {
        plantName = this.authService.getPlantNameFromId(itemData.plantId);
      }
      sheetDataIndex = sheetDataIndex + 1;
      dataIndex[itemData.itemId] = sheetDataIndex;
      lineItemObject.plantData.items.status.value = this.getFieldValue(itemData, 'status');
      lineItemObject.plantData.items.region.value = this.getFieldValue(itemData, 'region');
      lineItemObject.plantData.items.itemId.value = itemData.itemId;
      lineItemObject.plantData.items.materialDescription.value = lodash.has(itemData, 'materialDescription') ? data[i].materialDescription : '';
      lineItemObject.plantData.items.brand.value = this.getFieldValue(itemData, 'brand');
      lineItemObject.plantData.items.uom.value = this.getFieldValue(itemData, 'uom');

      lineItemObject.catalogData.items.teamName.value = this.getFieldValue(itemData, 'teamName');
      lineItemObject.catalogData.items.isArcRfqType.value = this.getFieldValue(itemData, 'isArcRfqType');
      lineItemObject.catalogData.items.buyersPoId.value = this.getFieldValue(itemData, 'poId');
      lineItemObject.catalogData.items['product.extraDetails.clientData.annexure'].value = this.getFieldValue(itemData, 'additionalDescription');
      lineItemObject.catalogData.items['sourcing.suggestedBrand'].value = this.getFieldValue(itemData, 'suggestedBrand');
      lineItemObject.catalogData.items.plantId.value = this.getFieldValue(itemData, 'plantId');
      lineItemObject.catalogData.items.plantName.value = plantName;
      lineItemObject.catalogData.items.cpn.value = this.getFieldValue(itemData, 'cpn');
      lineItemObject.catalogData.items.isArc.value = this.getFieldValue(itemData, 'isArc');
      lineItemObject.catalogData.items['catalog.urgencyClassification'].value = this.getFieldValue(itemData, 'urgencyClassification');
      lineItemObject.catalogData.items['catalog.priority'].value = this.getFieldValue(itemData, 'priority');
      lineItemObject.catalogData.items.sourcerEmail.value = this.getFieldValue(itemData, 'sourcingAllocation');
      lineItemObject.catalogData.items['delivery.opsAllocation'].value = this.getFieldValue(itemData, 'customerEmail');
      lineItemObject.catalogData.items['catalog.assignee'].value = this.getFieldValue(itemData, 'assignee');
      lineItemObject.catalogData.items['catalog.catalogPoc'].value = this.getFieldValue(itemData, 'catalogPoc');
      lineItemObject.catalogData.items['catalog.catalogStatus'].value = this.getFieldValue(itemData, 'catalogStatus');
      lineItemObject.catalogData.items.msn.value = this.getFieldValue(itemData, 'msn');
      lineItemObject.catalogData.items.msnDescription.value = this.getFieldValue(itemData, 'activeMsnDesc');
      lineItemObject.catalogData.items['catalog.clarificationClosed'].value = this.getFieldValue(itemData, 'clarificationClosed');
      lineItemObject.catalogData.items['catalog.remarkFromCatalog'].value = this.getFieldValue(itemData, 'remarkFromCatalog');
      lineItemObject.catalogData.items['catalog.remarkFromBusiness'].value = this.getFieldValue(itemData, 'remarkFromBusiness');
      lineItemObject.catalogData.items.totalInvoiceValue.value = this.getFieldValue(itemData, 'totalInvoiceValue');
      lineItemObject.catalogData.items.rfqMsn.value = this.getFieldValue(itemData, 'rfqMsn');
      lineItemObject.catalogData.items.hsn.value = this.getFieldValue(itemData, 'hsn');
      lineItemObject.catalogData.items.tax.value = this.getFieldValue(itemData, 'tax');
      lineItemObject.catalogData.items.needToWork.value = this.getFieldValue(itemData, 'needToWork');
      lineItemObject.catalogData.items.timeOfRequest.value = this.validationService.formatDateAndTime(this.getFieldValue(itemData, 'timeOfRequest'));
      lineItemObject.catalogData.items['remarks.remark4'].value = this.getFieldValue(itemData, 'remark4');
      lineItemObject.catalogData.items['catalog.additionalRemark1'].value = this.getFieldValue(itemData, 'additionalRemark1');
      lineItemObject.catalogData.items['msnSuggestions'].value = this.getFieldValue(itemData, 'msnSuggestions');
      lineItemObject.catalogData.items['catalog.additionalRemark2'].value = this.getFieldValue(itemData, 'additionalRemark2');
      lineItemObject.catalogData.items['moglixDescription'].value = this.getFieldValue(itemData, 'moglixDescription');
      if (!this.checkEmptyOrNull(lineItemObject.catalogData.items.msn.value)) {
        lineItemObject.catalogData.items.needToWork.disable = true;
        lineItemObject.catalogData.items.needToWork.inputType = '';
      }
      poDataSet.push(lineItemObject)

    }
    return { poDataSet, dataIndex };
  }


  public checkEmptyOrNull(value) {
    if (value === null || value === undefined || value === '') {
      return true;
    }
    return false;
  }

  convertSheetValueToObjectV3(data) {
    if (data) {
      let itemSheetModel = {};
      data.forEach(element => {
        let key = element.id;
        if (element.id) {
          itemSheetModel[key] = element.value;
        }
      });
      return itemSheetModel;
    }
  }

  async makeDataForUpdateItemInCatalogSheet(data: any, editedItem?) {
    const itemSheetModel = editedItem.editObj;
    if (!(lodash.has(editedItem, 'id'))) {
      return null;
    }
    let sourcerEmailCheck: any = true;
    if (!this.checkEmptyOrNull(itemSheetModel.sourcerEmail)) {
      if (this.validationService.emailFormatValidationCheck(itemSheetModel.sourcerEmail)) {
        let emailcheck = [];
        itemSheetModel.sourcerEmail = itemSheetModel.sourcerEmail.toLowerCase();
        emailcheck.push(itemSheetModel.sourcerEmail)
        sourcerEmailCheck = await this.poItemService.checkSourcerEmail(emailcheck);
      }
      else {
        sourcerEmailCheck = false;
      }
    }
    const validCustomerPOC = this.validationService.moglixEmailFormatValidationCheck(itemSheetModel['delivery.opsAllocation'], 'customerPoc');
    const activeCustomerPOC = validCustomerPOC && !this.validationService.checkEmptyOrNull(itemSheetModel['delivery.opsAllocation']) ? await lastValueFrom(this.validationService.checkActiveCustomerPOC(itemSheetModel['delivery.opsAllocation'])) : false;
    if (sourcerEmailCheck && validCustomerPOC && activeCustomerPOC) {
      let itemObj = this.convertFinalEditedObj(editedItem);
      Object.keys(itemObj).forEach(validationfield => {
        if (this.validationFieldType[validationfield] == "string") {
          itemObj[validationfield] = this.validationService.getStringFormat(itemObj[validationfield]);
        }
        else if (this.validationFieldType[validationfield] == "number") {
          itemObj[validationfield] = this.validationService.getNumberFormat(itemObj[validationfield])
        }
        else if (this.validationFieldType[validationfield] == "date") {
          itemObj[validationfield] = this.validationService.getDateFormatToLongInt(itemObj[validationfield])
        }
        else {
          itemObj[validationfield] = this.validationService.getStringFormat(itemObj[validationfield]);
        }
      })
      return itemObj;
    }
    else {
      return 'error';
    }
  }

  convertFinalEditedObj(data) {
    if (data) {
      let itemSheetModel = data.editObj;
      if (Object.keys(data.editObj).length > 0) {
        itemSheetModel['_id'] = data.id;
      }
      return itemSheetModel;
    }
  }
}



